<template>
<div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-outline card-primary">
                    <div class="card-header">
                        <h2 class="card-title">NEUIGKEITEN</h2>

                        <div class="clearfix card-tools">
                            <div class="row align-items-center" >                          
                                <div class="text-right col-md-12">
                                    <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                                        <ul class="navbar-nav">        
                                            <!-- <li class="nav-item d-none d-md-block">
                                                <router-link class="btn btn-warning btn-sm mr-1" :to="{name: 'news.archive'}"><i class="fas fa-archive"></i> Archiv</router-link>
                                            </li> -->
                                            <li class="nav-item mr-1">
                                                <div class="input-group input-group-sm" style="margin-top: 0;">
                                                    <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="loadNews" :value="search" @input="$store.commit('news/changeSearch', $event.target.value)" />
                                                    <button type="submit" class="btn btn-default btn-sm" @click.prevent="loadNews">
                                                        <i class="fas fa-fw fa-search"></i>
                                                    </button>
                                                    <button v-if="this.search != ''" type="button" class="ml-1 btn btn-danger btn-sm" @click="resetSearch">
                                                        <i class="fas fa-fw fa-times"></i>
                                                    </button>
                                                </div>
                                            </li>

                                            <li class="nav-item">
                                                <button type="button" class="mr-1 btn btn-sm btn-default" @click="loadNews">
                                                    <i class="fas fa-sync"></i>
                                                </button>
                                            </li>
                                            <li class="nav-item d-none d-md-block">
                                                <button class="btn btn-primary btn-sm mr-1" @click="showNewsModal" v-if="$auth.check('news.create')"><i class="fas fa-plus-circle"></i></button>             
                                            </li>
                                            <li class="nav-item d-none d-md-block">
                                                <button type="button" class="btn btn-sm btn-secondary" data-widget="control-sidebar"><i class="fas fa-filter"></i></button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    
                    <div class="card-body">

                        <template v-id="news.data.length > 0">
                            <div class="table-responsive">
                                <table class="table table-hover table-sm">
                                    <thead>
                                        <th><input type="checkbox" v-model="selectAll" /></th>
                                        <th>Bild</th>
                                        <th>
                                            <a href="#" @click.prevent="changeSort('created_at')">Datum</a>
                                            <span v-if="this.params.sort_field == 'created_at' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'created_at' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th>
                                            <a href="#" @click.prevent="changeSort('title')">Titel</a>
                                            <span v-if="this.params.sort_field == 'title' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'title' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th>Kategorien</th>
                                        <th>Aktion</th>
                                    </thead>

                                    <tbody>
                                        <tr v-for="news in news.data" :key="news.id" @dblclick="$router.push({name: 'news.show', params:{ id: news.id}})">
                                            <td><input type="checkbox" :value="news.id" v-model="selectedNews"/></td>
                                            <td>
                                                <img :src="news.img_mobile" style="max-width: 60px"/>
                                            </td>
                                            <td>{{ news.created_at | moment }}</td>
                                            <td>{{ news.title }}</td>
                                            <td>
                                                <span v-for="topic in news.topics" :key="topic.id" class="badge badge-primary mr-1" :class="topic.color">{{ topic.name }}</span>
                                            </td>
                                            <td>                          
                                                <router-link class="mr-1 btn btn-success btn-xs" :to="{name: 'news.show', params: {id: news.id}}"><i class="fas fa-fw fa-eye"></i></router-link>
                                                <b-button class="mr-1" size="xs" @click="editNews(news)" variant="warning" v-if="$auth.check('news.edit')"><i class="fas fa-fw fa-edit"></i></b-button>
                                                <b-button size="xs" @click="deleteNews(news.id)" variant="danger" v-if="$auth.check('news.destroy')"><i class="fas fa-fw fa-trash"></i></b-button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </template>

                        <template v-if="news.data.length == 0">
                            <h4 class="p-4">Aktuell gibt es keine Neuigkeiten</h4>
                        </template>
                    </div>

                    <div class="card-footer">
                        <template v-if="news.data.length > 0">
                            <div class="row align-items-center">
                                <div class="col-md-9">
                                    <pagination class="float-left" :data="news" @pagination-change-page="loadNews" :limit="3"></pagination>
                                </div>
                                <div class="col-md-2">
                                    <span class="float-right">Anzeige Eintrag {{ news.meta.from }} - {{ news.meta.to }} von {{ news.meta.total }}</span>
                                </div>
                                <div class="col-md-1">
                                    <select class="float-right form-control form-control-sm" v-model="params.per_page">
                                        <option value="25">25 News</option>
                                        <option value="50">50 News</option>
                                        <option value="75">75 News</option>
                                        <option value="100">100 News</option>
                                        <option value="125">125 News</option>
                                        <option value="150">150 News</option>
                                    </select>
                                </div>
                            </div>                        
                        </template>
                    </div>                  
                </div>          

                <NewsModal :news="newsTransfer" />
            </div>
        </div>
    </div>

    <aside class="control-sidebar control-sidebar-light">
        <div class="p-3">
            <h3>Filteroptionen</h3>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Kategorie:</label>
                        <select v-model="params.topic_id" class="form-control form-control-sm">
                            <option :value="''">-- Filter Kategorie --</option>
                            <option v-for="topic in topics" :key="topic.id" :value="topic.id">{{ topic.name }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12 text-center">
                    <div class="form-group">
                        <button type="button" class="btn btn-sm btn-secondary" @click="resetFilter">Filter zurücksetzen</button>
                    </div>
                </div>
            </div>

        </div>  
    </aside>
</div>
</template>

<script>
import NewsModal from './components/Create';
import moment from 'moment'
import {mapState} from 'vuex';
// import ClassicEditor from '../../assets/packages/ckeditor5/src/ckeditor';

export default {
    name: "Neuigkeiten",
    title: "Neuigkeiten",
    components: {
      NewsModal,
  },

    data() {
        return {
            news: {
                data: []
            },
            selectedNews: [],
            newsTransfer: null,
            topics: [],
        }
    }, 

    watch:{
        params: {
            handler () {
                this.loadNews();
            },
            deep: true
        },
    },

    computed: {
        selectAll: {
            get: function () {
                return this.news.data ? this.selectedNews.length == this.news.data.length : false;
            },
            set: function (value) {
                var selected = [];

                if(value) {
                    this.news.data.forEach(function (article) {
                        selected.push(article.id);
                    });
                }

                this.selectedNews = selected;
            }
        }, 
        ...mapState('news', ['params', 'search', 'page'])
    },

    filters: {
        moment: function (date) {
            return moment(date).format('DD.MM.YYYY');
        }
    },

    methods:{

        resetFilter(){
            this.params.topic_id = '';
            this.params.per_page = 25;
        },


        editNews(News) {
            this.newsTransfer = News;
            this.$bvModal.show("newsModal");
        },

        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },

        resetSearch(){
            this.$store.commit('news/changeSearch', '');
            this.loadNews(); 
        },

        showNewsModal(){
            this.newsTransfer = null;
            this.$bvModal.show("newsModal");
        },

        deleteNews(id) {
            this.$swal({
                    title: "Möchtest du die News wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Löschen`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.axios
                            .delete("/news/" + id)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Die News gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                this.loadNews();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        },

        loadNews(page) {
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get("/news", {
                    params: {
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.news = response.data;
                    this.$store.commit('news/setPage', page);
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadTopics() {
            this.axios
            .get("/topics", {
                params: {
                    per_page: 150, 
                }
            })        
            .then((response) => {
                this.topics = response.data.data;
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
        }
    },

    created() {
        this.loadNews();  
        // this.loadTopics();
    },
}
</script>

<style scoped>

::v-deep img {
    max-width: 80vh;
}

</style>
